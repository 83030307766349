import { Button, Typography } from '@material-ui/core';
import React from 'react';
import {
  ChatHeading,
  ChatButtonText,
  ChatHeadingTitle,
} from '../../../constants/landing';
import { useBatteryContext } from '../../../context/battery.context';
import { openChatWindow } from '../../../utils/chat';
import { useChatBannerStyles } from './ChatBanner.styles';

const ChatBanner = () => {
  const classes = useChatBannerStyles();
  const { isDrawerOpen } = useBatteryContext();

  return (
    <div className={classes.banner}>
      {!isDrawerOpen && (
        <>
          <Typography className={classes.headingTitle}>
            {ChatHeadingTitle}
          </Typography>
          <div className={classes.horizontalLine} />
        </>
      )}
      <Typography className={classes.heading}>{ChatHeading}</Typography>
      <Button
        onClick={openChatWindow}
        classes={{
          root: classes.button,
          label: classes.buttonLabel,
        }}
      >
        {ChatButtonText}
      </Button>
    </div>
  );
};

export default ChatBanner;
