import { ThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import PageSelector from './components/Landing/PageSelector';
import { theme } from './constants/theme';
import BatteryProvider from './context/battery.context';

const App = () => {
  useEffect(() => {
    ReactGA.initialize('G-6P8WXH9SDP');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BatteryProvider>
      <ThemeProvider theme={theme}>
        <PageSelector />
      </ThemeProvider>
    </BatteryProvider>
  );
};

export default App;
